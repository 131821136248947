@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-pin-input.scss';

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

html {
  // overflow: hidden;
}

// Remove the default arrows on number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// Set this property in order to control the height of the emoji picker
em-emoji-picker {
  height: 300px;
}
