.pincode-input-container {
  .pincode-input-text {
    border: 0px;
    @apply rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300;
  }

  .pincode-input-text:focus {
    @apply rounded-md border-0 py-1.5 shadow-sm ring-2 ring-inset ring-indigo-500;
  }
}
